import {
  Box,
  Divider,
  FormControlLabel,
  FormLabel,
  Radio,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Button from "../../../components/Button";
import {
  arcticMistColor,
  errorColor,
  primaryColor,
  silverSageColor,
  slateGrayColor,
} from "../../../assets/colors";
import TextInput from "../../../components/TextInput";
import CustomTextInput from "../../../components/CustomTextInput";
import {
  GitPullRequestIcon,
  GlobeIcon,
  LinkIcon,
  LinkIcon1,
} from "../../../assets/icons";
import { useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { IP_REGEX, URL_REGEX } from "../../../utils/regex";

const Step1 = ({
  activeStep,
  setActiveStep,
  state,
  setState,
  values,
  setValues,
}) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const inputRefs = useRef([]);

  const handleNextStep = (e) => {
    e.preventDefault();
    const errorObj = {};

    if (state.websiteName === "")
      errorObj.websiteName = "لطفاً نام وب سایت را وارد نمایید.";
    if (!URL_REGEX.test(state.websiteAddress))
      errorObj.websiteAddress = "فرمت آدرس وب سایت صحیح نیست.";
    if (state.websiteAddress === "")
      errorObj.websiteAddress = "لطفاً آدرس وب سایت را وارد نمایید.";
    // if (state.websiteIp === "")
    //   errorObj.websiteIp = "لطفاً آی پی وب سایت را وارد نمایید.";
    if (
      !IP_REGEX.test(
        values[0] + "." + values[1] + "." + values[2] + "." + values[3]
      )
    )
      errorObj.websiteIp = "آدرس آی پی وارد شده صحیح نمی‌باشد.";
    if (values.every((value) => value.trim() === ""))
      errorObj.websiteIp = "لطفا آی پی آدرس را وارد نمایید.";

    setState({ errors: errorObj });

    if (Object.keys(errorObj).length > 0) return;

    setActiveStep(2);
  };

  const handleChange = (index, event) => {
    const newValue = event.target.value;
    if (/^\d{0,3}$/.test(newValue)) {
      // Ensure the input is up to 3 digits
      const newValues = [...values];
      newValues[index] = newValue;
      setValues(newValues);

      // Move focus to the next input box if the current one is filled
      if (newValue.length === 3 && index < 3) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && values[index] === "" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <>
      <Box
        sx={{
          width: { lg: 455 },
          pr: { lg: 3 },
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Box
            sx={{
              display: "flex",
              position: "relative",
              alignItems: "center",
              mb: 4,
            }}
          >
            <Box
              sx={{
                width: 30,
                height: { xs: 18, lg: 32 },
                backgroundColor: primaryColor,
                position: "absolute",
                left: { xs: -35, lg: -25 },
                borderTopRightRadius: 32,
                borderBottomRightRadius: 32,
              }}
            ></Box>
            <Typography
              sx={{
                ml: { xs: 0.5, lg: 2 },
                fontSize: { xs: 14, lg: 20 },
                fontWeight: 600,
              }}
            >
              اطلاعات وب سایت خود را وارد کنید:
            </Typography>
          </Box>
          <Box>
            <CustomTextInput
              label="نام وب سایت"
              placeholder="مثلا: ایران وب هاست"
              value={state.websiteName}
              startAdornment={
                <GlobeIcon width={18} height={18} color={slateGrayColor} />
              }
              onChange={(e) => setState({ websiteName: e.target.value })}
              error={state.errors.websiteName}
              helperText={state.errors.websiteName}
            />
            <CustomTextInput
              label="آدرس وب سایت"
              placeholder="https://www.iran.ir :مثلا"
              value={state.websiteAddress}
              startAdornment={
                <LinkIcon1 width={18} height={18} color={slateGrayColor} />
              }
              onChange={(e) => setState({ websiteAddress: e.target.value })}
              error={state.errors.websiteAddress}
              helperText={state.errors.websiteAddress}
            />
            <Typography
              sx={{ fontSize: 14, fontWeight: 600, position: "absolute" }}
            >
              آی پی وب سایت
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                justifyContent: "space-between",
                gap: 1,
              }}
            >
              {values.map((value, index) => (
                <Box key={index} sx={{ width: { lg: 127 } }}>
                  <CustomTextInput
                    type="tel"
                    key={index}
                    inputRef={(el) => (inputRefs.current[index] = el)}
                    value={value}
                    onChange={(e) => handleChange(index, e)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    maxLength={3}
                    error={state.errors.websiteIp}
                    hideHelperText
                    placeholder="1 1 1 1"
                  />
                </Box>
              ))}
            </Box>
            <Typography sx={{ fontSize: 12, color: errorColor, mt: "3px" }}>
              {state.errors.websiteIp}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            text={isMobile ? "قبلی" : "مرحله قبل"}
            onClick={() => setActiveStep(0)}
            fullWidth={false}
            color="arcticMist"
            sx={{
              width: { xs: 93, lg: 156 },
              border: `1px solid ${silverSageColor}`,
            }}
          />
          <Button
            text="مرحله بعد"
            onClick={handleNextStep}
            fullWidth={false}
            sx={{ width: { xs: 196, lg: 156 } }}
          />
        </Box>
      </Box>
      <Divider
        orientation="vertical"
        flexItem
        variant="middle"
        sx={{ mb: 10, display: { xs: "none", lg: "block" } }}
      />
    </>
  );
};

export default Step1;
