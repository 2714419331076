import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Button as MuiButton,
  Chip,
  DialogTitle,
  SwipeableDrawer,
  Divider,
} from "@mui/material";
import {
  arcticMistColor,
  forestShadowColor,
  primaryColor,
  silverCloudColor,
  silverSageColor,
  slateGrayColor,
  tangerineBlazeColor,
  tropicalGreenColor,
  vanillaCreamColor,
  whiteColor,
} from "../../assets/colors";
import {
  CardAddIcon,
  CheckFillIcon,
  ChevronLeftIcon,
  CloseIcon,
  CreditCardIcon,
  EditIcon,
  InfoIcon,
  MapPinIcon,
  UserIcon,
} from "../../assets/icons";
import Button from "../../components/Button";
import Title from "../../components/Title";
import { useDispatch, useSelector } from "react-redux";
import CustomTextInput from "../../components/CustomTextInput";
import {
  createElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  apiBaseUrl,
  appBarHeight,
  dmSans,
  drawerWidth,
} from "../../utils/constants";
import {
  addBankCardRequestAction,
  changeProfilePictureRequestAction,
  editUserAddressRequestAction,
  getBankCardsRequestAction,
} from "../../redux/actions/userActions";
import {
  ADD_BANK_CARD_STARTED,
  CHANGE_PROFILE_PICTURE_STARTED,
} from "../../redux/actionTypes";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "../../utils/canvasUtils";
import AddCompany from "./Profile/AddCompany";
import { statusObj } from "./constants";
import Address from "./Address";
import { AccountContext } from "../../context/AccountContext";
import { statusObj as statusObj2 } from "../Webgate/constants";
import AddCompanyMobile from "./Profile/AddCompanyMobile";
// --------------------------------------------------------------------------------
const Profile = ({ page, setPage, setOnClick }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { rType, bankCards, userCompany } = useSelector((state) => state.user);

  const { state, setState } = useContext(AccountContext);
  const [open, setOpen] = useState(false);
  const [iban, setIban] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [errors, setErrors] = useState({});

  const fileInputRef = useRef(null);

  useEffect(() => {
    dispatch(getBankCardsRequestAction());
  }, []);

  const handleAddBankAccount = (e) => {
    e.preventDefault();
    const errorObj = {};

    if (iban.length < 24) errorObj.iban = "شماره شبا وارد شده صحیح نیست.";
    if (iban === "") errorObj.iban = "لطفاً شماره شبا را وارد کنید.";

    setErrors(errorObj);

    if (Object.keys(errorObj).length > 0) return;

    dispatch(
      addBankCardRequestAction({ iban, currencyId: 3, setOpen, setErrors })
    );
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const addBankAccountContent = (
    <>
      <Box>
        <Box
          sx={{
            backgroundColor: silverSageColor,
            width: 85,
            height: 85,
            borderRadius: "15px",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CardAddIcon />
        </Box>
        <Box sx={{ display: "inline-block", ml: 1.5 }}>
          <Typography sx={{ fontWeight: 700, mb: 1.5 }}>
            افزودن شماره شبا
          </Typography>
          <Typography sx={{ fontSize: 14, color: slateGrayColor }}>
            شماره شبا بانک خود را در کادر زیر وارد نمایید.
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row-reverse", mb: 2 }}>
        <Box
          sx={{
            backgroundColor: arcticMistColor,
            width: 45,
            height: 46,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 2,
            ml: 1,
            mt: 3.5,
          }}
        >
          <Typography sx={{ fontSize: 20, fontWeight: 700 }}>IR</Typography>
        </Box>
        <Box sx={{ width: 370 }}>
          <CustomTextInput
            value={iban}
            placeholder="... وارد کنید"
            onChange={(e) => setIban(e.target.value)}
            maxLength={24}
            sx={{ direction: "rtl" }}
            helperText={errors.iban}
            error={errors.iban}
            number
          />
        </Box>
      </Box>
      <Button
        text="ثبت حساب بانکی"
        onClick={handleAddBankAccount}
        loading={rType === ADD_BANK_CARD_STARTED}
      />
    </>
  );

  const renderAddBankAccount = (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          borderRadius: 5,
          boxShadow: "0px 0px 77px 20px #5F5F5F08;",
        },
      }}
      BackdropProps={{
        sx: {
          background: "rgba(218, 218, 218, 0.21)",
          backdropFilter: " blur(9px)",
          left: drawerWidth + 252,
          top: appBarHeight,
          borderLeft: "1px solid #E7EFEC",
        },
      }}
      sx={{ left: drawerWidth + 252, top: appBarHeight }}
      onClose={() => setOpen(false)}
    >
      <IconButton
        aria-label="close"
        onClick={() => setOpen(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>{addBankAccountContent}</DialogContent>
    </Dialog>
  );

  const renderAddAccountDrawer = (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      // onClose={toggleDrawer(anchor, false)}
      // onOpen={toggleDrawer(anchor, true)}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: {
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: "13px",
          py: 2.25,
        }}
      >
        <Title text="افزودن حساب بانکی" />
      </Box>
      <Divider sx={{ borderColor: silverSageColor }} />
      <Box sx={{ px: "27px", pt: "18px", pb: "27px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            mb: 2,
          }}
        >
          <Box
            sx={{
              backgroundColor: arcticMistColor,
              width: 45,
              height: 46,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 2,
              ml: 1,
              mt: 3.5,
            }}
          >
            <Typography sx={{ fontSize: 20, fontWeight: 700 }}>IR</Typography>
          </Box>
          <Box sx={{ width: 370 }}>
            <CustomTextInput
              value={iban}
              label="شماره شبا"
              placeholder="... وارد کنید"
              onChange={(e) => setIban(e.target.value)}
              maxLength={24}
              sx={{ direction: "rtl" }}
              helperText={errors.iban}
              error={errors.iban}
              number
            />
          </Box>
        </Box>
        <Button
          text="ثبت حساب بانکی"
          onClick={handleAddBankAccount}
          loading={rType === ADD_BANK_CARD_STARTED}
        />
      </Box>
    </SwipeableDrawer>
  );

  switch (page) {
    case "UserInformation":
      return (
        <Box sx={{ flex: 1, py: "15px", px: "27px" }}>
          <Box sx={{ display: "flex", flexWrap: "wrap", columnGap: 2 }}>
            <Box sx={{ flex: "1 1 40%" }}>
              <CustomTextInput
                label="نام"
                value={user.first_name}
                endAdornment={<CheckFillIcon width={18} height={18} />}
                sx={{
                  "&.MuiInputBase-root": {
                    backgroundColor: arcticMistColor,
                    border: "none!important",
                  },
                }}
              />
            </Box>
            <Box sx={{ flex: "1 1 40%" }}>
              <CustomTextInput
                label="نام خانوادگی"
                value={user.last_name}
                endAdornment={<CheckFillIcon width={18} height={18} />}
                sx={{
                  "&.MuiInputBase-root": {
                    backgroundColor: arcticMistColor,
                    border: "none!important",
                  },
                }}
              />
            </Box>
            <Box sx={{ flex: "1 1 100%" }}>
              <CustomTextInput
                label="کد ملی"
                value={user.national_code}
                startAdornment={<CheckFillIcon width={18} height={18} />}
              />
            </Box>
            <Box sx={{ flex: "1 1 100%" }}>
              <CustomTextInput
                label="موبایل"
                value={user.mobile}
                startAdornment={<CheckFillIcon width={18} height={18} />}
              />
            </Box>
            <Box sx={{ flex: "1 1 100%" }}>
              <CustomTextInput
                label="ایمیل"
                value={user.email}
                startAdornment={<CheckFillIcon width={18} height={18} />}
              />
            </Box>
          </Box>
        </Box>
      );

    case "AddressInformation":
      return (
        <Box sx={{ flex: 1, py: "15px", px: "27px" }}>
          <Box sx={{ display: "flex", flexWrap: "wrap", columnGap: 2 }}>
            <Box sx={{ flex: "1 1 40%" }}>
              <CustomTextInput
                label="کشور"
                value={state.country}
                endAdornment={<CheckFillIcon width={18} height={18} />}
                onChange={(e) => setState({ country: e.target.value })}
              />
            </Box>
            <Box sx={{ flex: "1 1 40%" }}>
              <CustomTextInput
                label="استان"
                value={state.state}
                endAdornment={<CheckFillIcon width={18} height={18} />}
                onChange={(e) => setState({ state: e.target.value })}
              />
            </Box>
            <Box sx={{ flex: "1 1 40%" }}>
              <CustomTextInput
                label="شهر"
                value={state.city}
                endAdornment={<CheckFillIcon width={18} height={18} />}
                onChange={(e) => setState({ city: e.target.value })}
              />
            </Box>
            <Box sx={{ flex: "1 1 40%" }}>
              <CustomTextInput
                label="کد پستی"
                value={state.postalCode}
                endAdornment={<CheckFillIcon width={18} height={18} />}
                sx={{
                  "& .MuiInputBase-input": {
                    direction: "ltr!important",
                  },
                }}
                onChange={(e) => setState({ postalCode: e.target.value })}
              />
            </Box>
            <Box sx={{ flex: "1 1 100%" }}>
              <CustomTextInput
                label="آدرس"
                value={state.address}
                endAdornment={<CheckFillIcon width={18} height={18} />}
                onChange={(e) => setState({ address: e.target.value })}
              />
            </Box>
          </Box>
        </Box>
      );
    case "AccountInformation":
      return (
        <>
          {bankCards.length > 0 ? (
            <Box sx={{ flex: 1, px: "20px", pt: "18px" }}>
              <Box
                sx={{
                  backgroundColor: arcticMistColor,
                  borderRadius: "8px",
                  mb: "15px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    px: "13px",
                    py: "12px",
                    height: 71,
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: 10,
                        fontWeight: 500,
                        color: slateGrayColor,
                        mb: 0.5,
                      }}
                    >
                      نام بانک
                    </Typography>
                    <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
                      {bankCards[0].bank_branch}
                    </Typography>
                  </Box>
                  {/* <Typography sx={{ fontSize: 12 }}>فعال</Typography> */}
                  <Title text="فعال" size={4} fontWeight={300} fontSize={12} />
                </Box>
                <Divider
                  variant="middle"
                  sx={{ borderColor: silverSageColor }}
                />
                <Box sx={{ height: 92, pt: "19px", pl: "26px", pr: "22px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: "18px",
                    }}
                  >
                    <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
                      نام صاحب حساب
                    </Typography>
                    <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
                      {bankCards[0].bank_response_first_name}{" "}
                      {bankCards[0].bank_response_last_name}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
                      شبا
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontWeight: 500,
                      }}
                    >
                      {bankCards[0].iban}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="outlined"
                  text="تغییر حساب"
                  fullWidth={false}
                  sx={{
                    // ml: "auto",
                    color: slateGrayColor,
                    borderRadius: "47px",
                    fontSize: 10,
                  }}
                  startIcon={<EditIcon width={14} height={14} />}
                  color="silverSage"
                  onClick={() => setOpen(true)}
                  disabled={user.user_address_status === "Pending"}
                />
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box sx={{ textAlign: "center" }}>
                <Box
                  sx={{
                    backgroundColor: silverSageColor,
                    width: 85,
                    height: 85,
                    borderRadius: "15px",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: "22px",
                  }}
                >
                  <CardAddIcon />
                </Box>
                <Typography sx={{ fontWeight: 700, mb: "12px" }}>
                  حساب بانکی ثبت نشده است!
                </Typography>
                <Typography
                  sx={{ fontSize: 14, color: slateGrayColor, mb: "16px" }}
                >
                  برای شروع حساب بانکی خود را ثبت کنید
                </Typography>
                <Button
                  text="ثبت حساب بانکی جدید"
                  sx={{ width: 262 }}
                  onClick={() => setOpen(true)}
                />
              </Box>
            </Box>
          )}
          {renderAddAccountDrawer}
        </>
      );
    case "CompanyInformation":
      return <AddCompanyMobile />;
    default:
      return (
        <Box sx={{ flex: 1, p: { xs: "15px", lg: 3 } }}>
          <Title
            text="پروفایل کاربری من"
            size={4}
            containerProps={{
              sx: { display: { xs: "none", lg: "flex" }, mb: "21px" },
            }}
          />
          <Box
            sx={{
              border: { xs: "none", lg: `1px solid ${silverSageColor}` },
              height: 102,
              borderRadius: { xs: "12px", lg: "15px" },
              p: 1,
              display: "flex",
              alignItems: "center",
              backgroundColor: { xs: arcticMistColor, lg: whiteColor },
              mb: { xs: "21px", lg: "14px" },
            }}
          >
            {/* <Avatar
              sx={{ width: { xs: 65, lg: 76 }, height: { xs: 65, lg: 76 } }}
            /> */}
            <Box sx={{ position: "relative" }}>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={async (e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    const file = e.target.files[0];
                    let imageDataUrl = await readFile(file);

                    setProfilePicture(imageDataUrl);
                    setShowProfileModal(true);
                  }
                }}
                accept=".png, .jpg, .jpeg"
              />
              <Avatar
                sx={{
                  width: 75,
                  height: 75,
                  background:
                    "linear-gradient(142.29deg, #03B585 15.91%, #05795A 87.4%)",
                  borderRadius: 100,
                }}
                src={`${apiBaseUrl}/${user.profile_picture}`}
              >
                <UserIcon
                  width={64.96}
                  height={64.96}
                  style={{ marginTop: 15 }}
                />
              </Avatar>
              <Chip
                label="ویرایش"
                color="arcticMist3"
                icon={<EditIcon width={12} height={12} />}
                sx={{
                  position: "absolute",
                  bottom: -2.5,
                  fontSize: 10,
                  width: 57,
                  height: 18,
                  left: 10,
                  "& .MuiChip-label": {
                    fontWeight: 500,
                    pr: 0.5,
                    pl: 1,
                  },
                  "&:hover": {
                    backgroundColor: arcticMistColor,
                  },
                }}
                onClick={() => fileInputRef.current.click()}
              />
            </Box>
            <Box sx={{ ml: 1, flex: 1 }}>
              <Typography sx={{ fontSize: 15, fontWeight: 600, mb: 0.5 }}>
                {user.first_name} {user.last_name}
              </Typography>
              <Box sx={{ mb: 0.5 }}>
                <Box
                  sx={{
                    width: 3,
                    height: 3,
                    backgroundColor: tropicalGreenColor,
                    display: "inline-block",
                    boxShadow: "0px 0px 0px 1px #85DBBC",
                    verticalAlign: "middle",
                    mr: 0.75,
                    borderRadius: 100,
                  }}
                ></Box>
                <Typography
                  sx={{
                    fontSize: { xs: 10, lg: 12 },
                    fontWeight: 600,
                    mr: 0.5,
                    display: "inline-block",
                  }}
                >
                  اشتراک فعال:
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: 10, lg: 12 },
                    display: "inline-block",
                  }}
                >
                  {user.plan_name}
                </Typography>
              </Box>
              {user.remaining_days ? (
                <Box>
                  <Typography
                    sx={{
                      fontSize: { xs: 10, lg: 12 },
                      fontWeight: 600,
                      mr: 0.5,
                      display: "inline-block",
                    }}
                  >
                    باقی مانده:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: 10, lg: 12 },
                      display: "inline-block",
                    }}
                  >
                    {user.remaining_days} روز
                  </Typography>
                </Box>
              ) : null}
            </Box>
          </Box>
          <CustomButton
            icon={UserIcon}
            text="اطلاعات کاربری"
            onClick={() =>
              setPage({ name: "اطلاعات کاربری", value: "UserInformation" })
            }
            sx={{ mb: 1, display: { lg: "none" } }}
          />
          <CustomButton
            icon={MapPinIcon}
            text="اطلاعات آدرس"
            onClick={() => {
              setPage({ name: "اطلاعات آدرس", value: "AddressInformation" });
              setState({
                country: user.country,
                state: user.state,
                city: user.city,
                postalCode: user.postal_code,
                address: user.address,
              });
            }}
            sx={{ mb: 1, display: { lg: "none" } }}
            status={user?.user_address_status}
          />
          <CustomButton
            icon={CreditCardIcon}
            text="اطلاعات حساب بانکی"
            onClick={() =>
              setPage({
                name: "اطلاعات حساب بانکی",
                value: "AccountInformation",
              })
            }
            sx={{ mb: "31px", display: { lg: "none" } }}
          />
          <Box sx={{ display: { xs: "none", lg: "flex" }, mb: "14px" }}>
            <Box
              sx={{
                border: `1px solid ${silverSageColor}`,
                borderRadius: "15px",
                p: 1.5,
                flex: 0.5,
                mr: "14px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <UserIcon
                  width={24}
                  height={24}
                  color={primaryColor}
                  style={{ marginLeft: 5, marginBottom: 5 }}
                />
                <Typography sx={{ fontWeight: 700 }}>اطلاعات کاربری</Typography>
                {/* <Button
                  variant="outlined"
                  text="ویرایش"
                  fullWidth={false}
                  sx={{
                    ml: "auto",
                    color: slateGrayColor,
                    borderRadius: "47px",
                  }}
                  startIcon={<EditIcon />}
                  color="silverSage"
                /> */}
              </Box>
              <Box
                sx={{ display: "flex", flexWrap: "wrap", gap: 2, rowGap: 4 }}
              >
                <Box sx={{ flex: "1 1 30%" }}>
                  <Typography sx={{ fontSize: 12 }}>نام</Typography>
                  <Typography>{user.first_name}</Typography>
                </Box>
                <Box sx={{ flex: "1 1 30%" }}>
                  <Typography sx={{ fontSize: 12 }}>نام خانوادگی</Typography>
                  <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
                    {user.last_name}
                  </Typography>
                </Box>
                <Box sx={{ flex: "1 1 30%" }}>
                  <Typography sx={{ fontSize: 12 }}>کد ملی</Typography>
                  <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
                    {user.national_code}
                  </Typography>
                </Box>

                <Box sx={{ flex: "1 1 30%" }}>
                  <Typography sx={{ fontSize: 12 }}>موبایل</Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: 14,
                      direction: "rtl",
                      textAlign: "left",
                    }}
                  >
                    {user.mobile}
                  </Typography>
                </Box>
                <Box sx={{ flex: "1 1 60%" }}>
                  <Typography sx={{ fontSize: 12 }}>ایمیل</Typography>
                  <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
                    {user.email}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Address />
          </Box>

          <Box
            sx={{
              border: `1px solid ${silverSageColor}`,
              borderRadius: "15px",
              p: 1,
              // height: 68,
              display: { xs: "none", lg: "block" },
              mb: "19px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CreditCardIcon color={primaryColor} style={{ marginLeft: 5 }} />
              <Typography sx={{ fontWeight: 700 }}>
                اطلاعات حساب بانکی
              </Typography>
              <Button
                text="+ تغییر حساب بانکی"
                fullWidth={false}
                sx={{ ml: "auto", borderRadius: "47px" }}
                color="emeraldOasis"
                onClick={() => setOpen(true)}
              />
            </Box>
            <TableContainer sx={{ mt: 2 }}>
              <Table sx={{}} aria-label="simple table">
                <TableBody>
                  {bankCards.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ borderColor: silverSageColor }}
                      >
                        <Typography
                          sx={{ mb: 1, fontSize: 12, fontWeight: 500 }}
                        >
                          نام بانک
                        </Typography>
                        {row.bank_branch}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ borderColor: silverSageColor }}
                      >
                        <Typography
                          sx={{ mb: 1, fontSize: 12, fontWeight: 500 }}
                        >
                          نام صاحب حساب
                        </Typography>
                        {row.bank_response_first_name}{" "}
                        {row.bank_response_last_name}
                      </TableCell>
                      <TableCell sx={{ borderColor: silverSageColor }}>
                        <Typography
                          sx={{ mb: 1, fontSize: 12, fontWeight: 500 }}
                        >
                          شماره شبا
                        </Typography>
                        {row.iban}
                      </TableCell>
                      <TableCell sx={{ borderColor: silverSageColor }}>
                        <Typography
                          sx={{ mb: 1, fontSize: 12, fontWeight: 500 }}
                        >
                          وضعیت
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: 14,
                            color: statusObj[row.status]?.color,
                          }}
                        >
                          {statusObj[row.status]?.label}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box
            sx={{
              backgroundColor: vanillaCreamColor,
              p: 1,
              py: 0.75,
              borderRadius: "9px",
              mb: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: 10, lg: 12 },
                fontWeight: 500,
                lineHeight: 2.5,
              }}
            >
              <InfoIcon
                style={{ marginLeft: 5, verticalAlign: "middle" }}
                color={tangerineBlazeColor}
                width={20}
                height={20}
              />
              جهت تغییر حساب خود از “حقیقی” به “حقوقی” اطلاعات شرکت خود را وارد
              کنید.
            </Typography>
          </Box>
          <CustomButton
            icon={CreditCardIcon}
            text="اطلاعات شرکت"
            onClick={() => {
              setPage({
                name: "اطلاعات شرکت",
                value: "CompanyInformation",
              });
              setState({
                name: userCompany.name,
                registrationNumber: userCompany.national_id,
                email: userCompany.email,
                lastChanges: userCompany.last_changes,
                logo: userCompany.logo,
                officialNewspaper: userCompany.official_newspaper,
                companyOwners: userCompany.company_owners.map((owenr) => ({
                  signatureImage: owenr.attachment,
                  firstname: owenr.first_name,
                  lastname: owenr.last_name,
                  mobile: owenr.mobile,
                  nationalId: owenr.national_id,
                })),
              });
            }}
            sx={{ mb: "31px", display: { lg: "none" } }}
          />
          <AddCompany />
          {renderAddBankAccount}
          <Dialog
            open={showProfileModal}
            onClose={() => setShowProfileModal(false)}
            PaperProps={{
              sx: {
                borderRadius: 5,
                boxShadow: "0px 0px 77px 20px #5F5F5F08;",
                width: 408,
                mx: 1,
              },
            }}
            BackdropProps={{
              sx: {
                background: "rgba(218, 218, 218, 0.21)",
                backdropFilter: " blur(9px)",
                left: { lg: drawerWidth + 252 },
                top: appBarHeight,
                borderLeft: "1px solid #E7EFEC",
              },
            }}
            sx={{ left: { lg: drawerWidth + 252 }, top: appBarHeight }}
          >
            <IconButton
              aria-label="close"
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
              onClick={() => setShowProfileModal(false)}
            >
              <CloseIcon width={20} height={20} color={forestShadowColor} />
            </IconButton>
            <DialogTitle sx={{ fontSize: 14, fontWeight: 600 }}>
              ویرایش تصویر
            </DialogTitle>
            <DialogContent>
              <Box
                sx={{
                  width: { lg: 358 },
                  height: 214,
                  position: "relative",
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
              >
                <Cropper
                  image={profilePicture}
                  crop={crop}
                  zoom={zoom}
                  aspect={5 / 5}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  cropShape="round"
                  showGrid={false}
                  classes={{
                    containerClassName: "crop-container",
                    cropAreaClassName: "crop-media",
                  }}
                />
              </Box>
            </DialogContent>
            <DialogActions
              sx={{ justifyContent: "center", pt: 0, pb: "18px", px: "24px" }}
            >
              <Button
                text="انصراف"
                color="arcticMist"
                onClick={() => setShowProfileModal(false)}
              />
              <Button
                text="ثبت پروفایل"
                sx={{ boxShadow: "none" }}
                onClick={async () => {
                  try {
                    const croppedImage = await getCroppedImg(
                      profilePicture,
                      croppedAreaPixels
                    );
                    console.log("donee", { croppedImage });
                    // setCroppedImage(croppedImage);

                    // setImageSrc(null);
                    let formData = new FormData();
                    formData.append("profile", croppedImage);
                    dispatch(
                      changeProfilePictureRequestAction({
                        formData,
                        setShowProfileModal,
                      })
                    );
                  } catch (e) {
                    console.error(e);
                  }
                }}
                loading={rType === CHANGE_PROFILE_PICTURE_STARTED}
              />
            </DialogActions>
          </Dialog>
        </Box>
      );
  }
};

export default Profile;

const CustomButton = ({ text, icon, onClick, sx, status }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      backgroundColor: arcticMistColor,
      height: "50px",
      borderRadius: "6px",
      ...sx,
    }}
    component={MuiButton}
    fullWidth
    onClick={onClick}
  >
    {createElement(icon, {
      color: primaryColor,
      style: { marginLeft: "11px", marginRight: "13px" },
      width: 24,
      hright: 24,
    })}
    <Typography sx={{ fontSize: 14, fontWeight: 600, mr: status ? 0 : "auto" }}>
      {text}
    </Typography>
    {status && (
      <Chip
        label={statusObj2[status].label}
        color={statusObj2[status].color}
        sx={{
          ml: "8px",
          height: 20,
          fontSize: 12,
          fontWeight: 500,
          mr: "auto",
        }}
        icon={
          <Box
            sx={{
              backgroundColor: (theme) =>
                theme.palette[statusObj2[status].color].contrastText,
              height: 4,
              width: 4,
              borderRadius: 100,
            }}
          ></Box>
        }
      />
    )}
    <ChevronLeftIcon style={{ marginLeft: "9px" }} width={24} />
  </Box>
);

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}
