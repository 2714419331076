import { Avatar, Box, Chip, IconButton, Typography } from "@mui/material";
import {
  arcticMistColor,
  forestShadowColor,
  silverSageColor,
  slateGrayColor,
} from "../../../assets/colors";
import {
  ChevronDownIcon,
  ShoppingBagIcon,
  TrashIcon2,
} from "../../../assets/icons";
import { useState } from "react";
import { apiBaseUrl } from "../../../utils/constants";

const Item = ({ product, onDelete }) => {
  const [open, setOpen] = useState(false);

  return (
    <Box
      sx={{
        border: `1px solid ${silverSageColor}`,
        p: { xs: 1, lg: 2 },
        my: { lg: 3 },
        mb: { xs: 3, lg: 0 },
        borderRadius: 3,
        display: { lg: "none" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row-reverse",
        }}
      >
        <Avatar
          sx={{
            height: 32,
            width: 32,
            backgroundColor: forestShadowColor,
            borderRadius: "5px",
            ml: "9px",
          }}
          src={`${apiBaseUrl}/${product.image}`}
        >
          <ShoppingBagIcon width={24} />
        </Avatar>
        <Box sx={{ flex: 1, textAlign: "right", width: 150, ml: 1 }}>
          <Typography
            sx={{
              fontFamily: ["Noto Sans", "IRANSansXFaNum"],
              fontSize: 12,
              fontWeight: 500,
              mb: 0.5,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {product.title}
          </Typography>
          <Typography
            sx={{
              fontSize: 10,
              color: slateGrayColor,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {product.detail}
          </Typography>
        </Box>
        <IconButton
          sx={{ backgroundColor: arcticMistColor, ml: 1 }}
          onClick={onDelete}
        >
          <TrashIcon2 />
        </IconButton>
        <IconButton
          sx={{
            backgroundColor: arcticMistColor,
            ml: 1,
            ml: "auto",
            display: { lg: "none" },
          }}
          onClick={() => setOpen(!open)}
        >
          <ChevronDownIcon
            width={16}
            style={{ transform: open ? "rotate(180deg)" : "rotate(0deg)" }}
          />
        </IconButton>
      </Box>
      {open && (
        <Box sx={{}}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              py: 1,
              pt: 4,
            }}
          >
            <Typography sx={{ fontSize: 12 }}>نام محصول</Typography>
            <Typography sx={{ fontSize: 12 }}>{product.title}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              py: 1,
            }}
          >
            <Typography sx={{ fontSize: 12 }}>توضیحات</Typography>
            <Typography sx={{ fontSize: 12 }}>{product.description}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              py: 1,
            }}
          >
            <Typography sx={{ fontSize: 12 }}>قیمت</Typography>
            <Typography sx={{ fontSize: 12 }}>€{product.amount}</Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Item;
