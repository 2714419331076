import { Box, Typography } from "@mui/material";
import {
  arcticMistColor,
  coralRedColor,
  emeraldGreenColor,
  forestShadowColor,
  goldenrodColor,
  mintCreamColor,
  primaryColor,
  silverSageColor,
  slateGrayColor,
  whiteColor,
} from "../../../assets/colors";
import { useContext, useState } from "react";
import { CheckCircleIcon, CheckIcon, ImageIcon } from "../../../assets/icons";
import Ellipse90 from "./Ellipse90.svg";
import { useSelector } from "react-redux";
import moment from "moment-jalaali";
import { addBalanceSeparator } from "../../../utils/helpers";
import { PaylinkContext } from "../../../context/PyalinkContext";
import { dmSans } from "../../../utils/constants";

const ReceiptPreview = () => {
  const { user } = useSelector((state) => state.auth);
  const {
    state: {
      confirmationPage,
      defaultAmount,
      successfulLink,
      successfulMessage,
    },
  } = useContext(PaylinkContext);

  const [loading, setLoading] = useState(true);

  const handleIframeLoad = () => {
    setLoading(false);
  };

  return (
    <Box
      sx={{
        width: 718,
        height: 523,
        backgroundColor: arcticMistColor,
        borderRadius: 2.5,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          p: 2,
          boxShadow: "0px 10px 20px 0px rgba(0, 0, 0, 0.03)",
          backgroundColor: whiteColor,
        }}
      >
        <Box
          sx={{
            width: 10,
            height: 10,
            background: coralRedColor,
            borderRadius: 100,
            ml: 0.5,
          }}
        ></Box>
        <Box
          sx={{
            width: 10,
            height: 10,
            background: goldenrodColor,
            borderRadius: 100,
            ml: 0.5,
          }}
        ></Box>
        <Box
          sx={{
            width: 10,
            height: 10,
            background: emeraldGreenColor,
            borderRadius: 100,
          }}
        ></Box>
      </Box>
      {successfulLink ? (
        loading ? (
          <div
            style={{
              flex: 1,
            }}
          >
            Loading...
          </div>
        ) : (
          <iframe
            src={successfulLink}
            onLoad={handleIframeLoad}
            title="description"
            style={{ flex: 1, border: "none" }}
            sandbox="allow-scripts allow-same-origin"
          />
        )
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Box
            sx={{
              border: `1px solid ${mintCreamColor}`,
              backgroundColor: whiteColor,
              width: 294,
              height: 384,
              borderRadius: 2.5,
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Ellipse90 />
            <Box sx={{ mx: "auto", pt: 4, flex: 1, position: "relative" }}>
              <Box
                sx={{
                  width: 52,
                  height: 52,
                  backgroundColor: primaryColor,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: `4px solid ${whiteColor}`,
                  borderRadius: 100,
                  position: "absolute",
                  top: -26,
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <CheckIcon width={36.4} height={36.4} color={whiteColor} />{" "}
              </Box>
              <Typography
                sx={{
                  fontSize: 12,
                  color: forestShadowColor,
                  fontWeight: 700,
                  textAlign: "center",
                  mb: 2,
                  mt: 1,
                }}
              >
                !Successful Transaction
              </Typography>
              <Box
                sx={{
                  borderBottom: `1px solid ${silverSageColor}`,
                  mx: "auto",
                  mb: 3,
                  overflowWrap: "break-word",
                  width: "fit-content",
                  maxWidth: 223,
                  opacity: confirmationPage ? 1 : 0,
                  transition: "1s all",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: 10,
                    fontWeight: 500,
                    color: forestShadowColor,
                  }}
                >
                  {successfulMessage}
                </Typography>
              </Box>
              <Box
                sx={{
                  my: 1,
                  backgroundColor: arcticMistColor,
                  display: "flex",
                  p: 1,
                  width: 221,
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: 1,
                }}
              >
                <Typography sx={{ fontSize: 10, fontWeight: 500 }}>
                  {user.first_name} {user.last_name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 10,
                    color: slateGrayColor,
                  }}
                >
                  :Receiver
                </Typography>
              </Box>
              <Box
                sx={{
                  my: 1,
                  backgroundColor: arcticMistColor,
                  display: "flex",
                  p: 1,
                  width: 221,
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: 1,
                }}
              >
                <Typography sx={{ fontSize: 10, fontWeight: 500 }}>
                  {addBalanceSeparator(defaultAmount)}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 10,
                    color: slateGrayColor,
                  }}
                >
                  :Amount
                </Typography>
              </Box>
              <Box
                sx={{
                  my: 1,
                  backgroundColor: arcticMistColor,
                  display: "flex",
                  p: 1,
                  width: 221,
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: 1,
                }}
              >
                <Typography sx={{ fontSize: 10, fontWeight: 500 }}>
                  {moment().format("HH:mm - YYYY/MM/DD")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 10,
                    color: slateGrayColor,
                  }}
                >
                  :Date
                </Typography>
              </Box>
              <Box
                sx={{
                  my: 1,
                  backgroundColor: arcticMistColor,
                  display: "flex",
                  p: 1,
                  width: 221,
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: 1,
                }}
              >
                <Typography sx={{ fontSize: 10, fontWeight: 500 }}>
                  #324231
                </Typography>
                <Typography
                  sx={{
                    fontSize: 10,
                    color: slateGrayColor,
                  }}
                >
                  :Number
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ReceiptPreview;
