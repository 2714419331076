import { Box, Grid, InputAdornment, Typography } from "@mui/material";
import CustomTextInput from "../../components/CustomTextInput";
import Button from "../../components/Button";
import Title from "../../components/Title";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changePasswordRequestAction,
  checkPasswordRequestAction,
} from "../../redux/actions/settingActions";
import {
  CHANGE_PASSWORD_STARTED,
  CHECK_PASSWORD_STARTED,
} from "../../redux/actionTypes";
import {
  arcticMistColor,
  forestShadowColor,
  primaryColor,
  silverSageColor,
  slateGrayColor,
  whiteColor,
} from "../../assets/colors";
import { CheckIcon, KeyIcon2 } from "../../assets/icons";
import { useNavigate } from "react-router-dom";
import { fullHeight } from "../../utils/constants";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    setting: { rType },
    auth: { user },
  } = useSelector((state) => ({ setting: state.setting, auth: state.auth }));

  const [
    { currentPassword, newPassword, confirmNewPassword, passwordIsCheck },
    setInitState,
  ] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    passwordIsCheck: false,
  });
  const [errors, setErrors] = useState({});

  const newPasswordRef = useRef(null);

  useEffect(() => {
    if (passwordIsCheck) {
      newPasswordRef.current.focus();
    }
  }, [passwordIsCheck]);

  const setState = (state) => {
    setInitState((prevState) => ({ ...prevState, ...state }));
  };

  const onCheckEmail = (e) => {
    e.preventDefault();
    const errorObj = {};

    if (currentPassword === "")
      errorObj.currentPassword = "لطفاً رمز عبور فعلی خود را وارد کنید.";

    setErrors(errorObj);

    if (Object.keys(errorObj).length > 0) return;

    dispatch(
      checkPasswordRequestAction({
        currentPassword,
        setState,
        setErrors,
        newPasswordRef,
      })
    );
  };

  const handleChangePassword = (e) => {
    e.preventDefault();
    const errorObj = {};

    if (newPassword === "")
      errorObj.newPassword = "لطفاً رمز عبور جدید خود را وارد کنید.";
    if (confirmNewPassword === "")
      errorObj.confirmNewPassword = "لطفاً تکرار رمز جدید خود را وارد کنید.";
    if (newPassword !== confirmNewPassword)
      errorObj.confirmNewPassword =
        "رمز عبور جدید و تکرار آن با هم مطابقت ندارند.";

    setErrors(errorObj);

    if (Object.keys(errorObj).length > 0) return;

    dispatch(
      changePasswordRequestAction({
        currentPassword,
        newPassword,
        confirmNewPassword,
        setState,
      })
    );
  };

  return user.email ? (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        py: { lg: 2.25 },
        px: { lg: 3.75 },
      }}
    >
      <Grid
        sx={{
          mb: { xs: "auto", lg: 0 },
          py: { xs: 2.25, lg: 0 },
          px: { xs: 3.75, lg: 0 },
        }}
        container
        spacing={3}
      >
        <Grid item xs={12} sx={{ display: { xs: "none", lg: "block" }, mb: 3 }}>
          <Title text="تغییر رمز عبور حساب" size={4} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomTextInput
            label="رمز عبور فعلی"
            value={currentPassword}
            type="password"
            placeholder="... وارد کنید"
            onChange={(e) => setState({ currentPassword: e.target.value })}
            helperText={errors.currentPassword}
            error={errors.currentPassword}
            disabled={passwordIsCheck}
            endAdornment={
              passwordIsCheck && (
                <InputAdornment position="end">
                  <Box
                    sx={{
                      width: 20,
                      height: 20,
                      backgroundColor: primaryColor,
                      borderRadius: 100,
                    }}
                  >
                    <CheckIcon color={whiteColor} />
                  </Box>
                </InputAdornment>
              )
            }
            sx={{
              "&.MuiInputBase-root": {
                borderColor: passwordIsCheck
                  ? `${primaryColor}!important`
                  : "none",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomTextInput
            inputRef={newPasswordRef}
            label="رمز عبور جدید"
            value={newPassword}
            type="password"
            placeholder="... وارد کنید"
            onChange={(e) => setState({ newPassword: e.target.value })}
            helperText={errors.newPassword}
            error={errors.newPassword}
            disabled={!passwordIsCheck}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomTextInput
            label="تکرار رمز جدید"
            value={confirmNewPassword}
            type="password"
            placeholder="... وارد کنید"
            onChange={(e) => setState({ confirmNewPassword: e.target.value })}
            helperText={errors.confirmNewPassword}
            error={errors.confirmNewPassword}
            disabled={!passwordIsCheck}
          />
        </Grid>
      </Grid>
      <Grid
        sx={{
          position: "sticky",
          bottom: 0,
          left: 0,
          right: 0,
          p: 3,
          justifyContent: "center",
        }}
        container
        spacing={2}
      >
        <Grid item lg={4} xs={6}>
          <Button
            text={passwordIsCheck ? "تغییر رمز عبور" : "بررسی رمز عبور فعلی"}
            onClick={passwordIsCheck ? handleChangePassword : onCheckEmail}
            loading={
              rType === CHANGE_PASSWORD_STARTED ||
              rType === CHECK_PASSWORD_STARTED
            }
          />
        </Grid>
        <Grid item lg={4} xs={6}>
          <Button
            text="انصراف"
            color="arcticMist"
            onClick={() => {
              setState({
                currentPassword: "",
                newPassword: "",
                confirmNewPassword: "",
                passwordIsCheck: false,
              });
              setErrors({});
            }}
          />
        </Grid>
      </Grid>
    </Box>
  ) : (
    <Box
      sx={{
        display: { lg: "flex" },
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
      }}
    >
      <Box
        sx={{
          // flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: { xs: `calc(${fullHeight} - 220px)`, lg: "auto" },
        }}
      >
        <Box
          sx={{
            backgroundColor: { lg: arcticMistColor },
            p: "30px",
            borderRadius: "20px",
            width: { lg: 488 },
          }}
        >
          <Box
            sx={{
              display: { lg: "flex" },
              textAlign: "center",
              alignItems: "center",
              mb: { lg: "32px" },
            }}
          >
            <Box
              sx={{
                backgroundColor: silverSageColor,
                width: 85,
                height: 85,
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "15px",
                mb: { xs: "32px", lg: 0 },
              }}
            >
              <KeyIcon2 width={43} height={43} color={forestShadowColor} />
            </Box>
            <Box sx={{ flex: 1, ml: 2 }}>
              <Typography sx={{ fontWeight: 700, mb: 1 }}>
                شما رمز عبوری برای حساب خود تعیین نکردید!
              </Typography>
              <Typography
                sx={{ lineHeight: 1.8, fontSize: 14, color: slateGrayColor }}
              >
                با کلیک روی دکمه پایین به صفحه ایجاد رمز عبور میرید و رمز حساب
                خودتون رو تعیین میکنید
              </Typography>
            </Box>
          </Box>
          <Button
            text="ایجاد رمز عبور"
            onClick={() => navigate("/email-confirmation")}
            sx={{ display: { xs: "none", lg: "flex" } }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          left: 0,
          right: 0,
          p: 3,
          justifyContent: "center",
          display: { xs: "block", lg: "none" },
        }}
      >
        <Button
          text="ایجاد رمز عبور"
          onClick={() => navigate("/email-confirmation")}
          sx={{ borderRadius: { xs: 100, lg: 2 } }}
        />
      </Box>
    </Box>
  );
};

export default ChangePassword;
