import {
  ChevronDownIcon,
  CopyIcon,
  EyeIcon,
  ShoppingBagIcon,
  TrashIcon2,
} from "../../../assets/icons";
import {
  arcticMistColor,
  coralSunset,
  forestShadowColor,
  goldenAmberColor,
  primaryColor,
  silverSageColor,
  whiteColor,
} from "../../../assets/colors";
import {
  Avatar,
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { statusObj } from "../../Account/constants";
import { useDispatch } from "react-redux";
import { cancelPersonalPaylinkRequestAction } from "../../../redux/actions/paylinkAction";
import Button from "../../../components/Button";
import {
  apiBaseUrl,
  appBarHeight,
  drawerWidth,
  iranSansFaNum,
} from "../../../utils/constants";

const Item = ({ paylink }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const renderDialog = (
    <Dialog
      open={showDialog}
      PaperProps={{
        sx: {
          borderRadius: 5,
          boxShadow: "0px 0px 77px 20px #5F5F5F08",
          width: 452,
        },
      }}
      BackdropProps={{
        sx: {
          background: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(9px)",
          left: { lg: drawerWidth },
          top: appBarHeight,
        },
      }}
      sx={{
        left: { lg: drawerWidth },
        top: appBarHeight,
        // display: { xs: "none", lg: "block" },
      }}
      onClose={() => setShowDialog(false)}
      scroll="body"
    >
      <DialogContent sx={{ pb: 0 }}>
        <Typography>دلایل رد شدن درخواست شما:</Typography>
      </DialogContent>
      <DialogContent>{paylink.admin_description}</DialogContent>
      <DialogActions
        sx={{
          justifyContent: "space-between",
          px: "24px",
          pb: "38px",
          pt: "17px",
        }}
      >
        {/* <Button
          color="silverSage"
          variant="outlined"
          text="بازگشت"
          sx={{ width: 131, borderRadius: 100, color: slateGrayColor }}
          onClick={() => setState({ openConfirm: false })}
        />
        <Button
          text="ثبت پی لینک"
          sx={{ borderRadius: 100, width: 253 }}
          loading={
            rType === CREATE_PERSONAL_PAYLINK_STARTED ||
            rType === CREATE_PRODUCT_PAYLINK_STARTED
          }
          onClick={() => {}}
        /> */}
      </DialogActions>
    </Dialog>
  );

  return (
    <Box
      sx={{
        border: `1px solid ${silverSageColor}`,
        p: { xs: 1, lg: 2 },
        my: { lg: 3 },
        mb: { xs: 3, lg: 0 },
        borderRadius: 3,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          sx={{
            height: { xs: 32, lg: 42 },
            width: { xs: 32, lg: 42 },
            mr: 1.5,
            backgroundColor: forestShadowColor,
          }}
          src={`${apiBaseUrl}/${paylink.image}`}
        >
          <Box component={ShoppingBagIcon} sx={{ width: { xs: 16, lg: 24 } }} />
        </Avatar>
        <Box>
          <Typography
            sx={{
              fontSize: { xs: 12, lg: 14 },
              fontWeight: 600,
              mb: 0.5,
            }}
          >
            {paylink.title}
          </Typography>
          <Typography
            sx={{
              color: statusObj[paylink.status]?.color,
              fontSize: { xs: 10, lg: 12 },
              fontWeight: 500,
            }}
          >
            <Box
              sx={{
                width: { xs: 5, lg: 7 },
                height: { xs: 5, lg: 7 },
                borderRadius: 100,
                backgroundColor: statusObj[paylink.status]?.color,
                display: "inline-block",
                mr: 0.4,
              }}
            ></Box>{" "}
            {statusObj[paylink.status]?.label}
          </Typography>
        </Box>
        {paylink.admin_description && (
          <IconButton
            onClick={() => setShowDialog(true)}
            sx={{
              backgroundColor: coralSunset,
              ml: "auto",
              display: { lg: "none" },
            }}
          >
            <EyeIcon width={16} color={whiteColor} />
          </IconButton>
        )}
        <IconButton
          sx={{
            backgroundColor: arcticMistColor,
            ml: { xs: paylink.admin_description ? 1 : "auto", lg: 1 },
            display: { lg: "none" },
          }}
          onClick={() => setOpen(!open)}
        >
          <ChevronDownIcon width={16} style={{ transform: "rotate(180deg)" }} />
        </IconButton>
        {paylink.admin_description && (
          <Chip
            label="مشاهده دلایل"
            onClick={() => setShowDialog(true)}
            color="coralSunset"
            sx={{
              ml: "auto",
              mr: 1,
              display: { xs: "none", lg: "inline-flex" },
            }}
          />
        )}
        <Chip
          label="مشاهده آمار"
          onClick={() => setOpen(!open)}
          deleteIcon={
            <ChevronDownIcon
              width={16}
              style={{ transform: "rotate(180deg)" }}
            />
          }
          color="arcticMist4"
          onDelete={() => {}}
          sx={{
            ml: paylink.admin_description ? 0 : "auto",
            display: { xs: "none", lg: "flex" },
          }}
        />
        <IconButton
          sx={{ backgroundColor: arcticMistColor, ml: 1 }}
          onClick={() => navigator.clipboard.writeText(paylink.link)}
        >
          <CopyIcon />
        </IconButton>

        {/* <IconButton sx={{ backgroundColor: arcticMistColor, ml: 1 }}>
          <EditIcon color={forestShadowColor} width={18} height={18} />
        </IconButton> */}
        <IconButton
          sx={{ backgroundColor: arcticMistColor, ml: 1 }}
          onClick={() =>
            dispatch(
              cancelPersonalPaylinkRequestAction({
                id: paylink.personal_paylink_id,
              })
            )
          }
        >
          <TrashIcon2 />
        </IconButton>
      </Box>
      {open && (
        <>
          <Box
            sx={{
              mt: 4,
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
            }}
          >
            <Box sx={{ width: { lg: 300 }, order: { xs: 2, lg: 1 } }}>
              <Box
                sx={{
                  backgroundColor: arcticMistColor,
                  borderRadius: 1.5,
                  p: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 2,
                }}
              >
                <Typography>حجم تراکنش امروز:</Typography>
                <Typography
                  sx={{ color: primaryColor, fontFamily: iranSansFaNum }}
                >
                  {paylink.today_paylink_amount}
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: arcticMistColor,
                  borderRadius: 1.5,
                  p: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 2,
                }}
              >
                <Typography>تعداد تراکنش امروز:</Typography>
                <Typography
                  sx={{ color: goldenAmberColor, fontFamily: iranSansFaNum }}
                >
                  {paylink.today_paylink_count}
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: arcticMistColor,
                  borderRadius: 1.5,
                  p: 1,
                  // display: "flex",
                  // alignItems: "center",
                  // justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: 700,
                    fontSize: 14,
                    mb: 1,
                  }}
                >
                  هفته گذشته
                </Typography>
                <Box sx={{ display: "flex", textAlign: "center" }}>
                  <Box sx={{ p: 2, flex: 0.5 }}>
                    <Typography
                      sx={{
                        color: primaryColor,
                        fontSize: 25,
                        fontWeight: 700,
                        lineHeight: 1.7,
                        fontFamily: iranSansFaNum,
                      }}
                    >
                      {paylink.week_paylink_amount}
                    </Typography>
                    <Typography sx={{ fontSize: 10 }}>جمع کل فروش</Typography>
                  </Box>
                  <Box>
                    <Divider orientation="vertical" />
                  </Box>
                  <Box sx={{ p: 2, flex: 0.5, textAlign: "center" }}>
                    <Typography
                      sx={{
                        color: goldenAmberColor,
                        fontSize: 30,
                        fontWeight: 700,
                        fontFamily: iranSansFaNum,
                      }}
                    >
                      {paylink.week_paylink_count}
                    </Typography>
                    <Typography sx={{ fontSize: 10 }}>تراکنش</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                pl: { lg: 3 },
                height: { xs: 120, lg: "unset" },
                width: "100%",
                order: 1,
                mb: { xs: 5, lg: 0 },
              }}
            >
              <ResponsiveContainer>
                <AreaChart
                  // width={492}
                  // height={250}
                  data={paylink.paylink_week}
                  margin={{ top: 10, right: 30, left: -30, bottom: 0 }}
                >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop
                        offset="5%"
                        stopColor={goldenAmberColor}
                        stopOpacity={0.1}
                      />
                      <stop
                        offset="95%"
                        stopColor={goldenAmberColor}
                        stopOpacity={0}
                      />
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop
                        offset="5%"
                        stopColor={primaryColor}
                        stopOpacity={0.1}
                      />
                      <stop
                        offset="95%"
                        stopColor={primaryColor}
                        stopOpacity={0}
                      />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="uv"
                    stroke={goldenAmberColor}
                    fillOpacity={1}
                    fill="url(#colorUv)"
                  />
                  <Area
                    type="monotone"
                    dataKey="pv"
                    stroke={primaryColor}
                    fillOpacity={1}
                    fill="url(#colorPv)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Box>
          </Box>
        </>
      )}
      {renderDialog}
    </Box>
  );
};

export default Item;
