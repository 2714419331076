import {
  Box,
  Divider,
  Typography,
  Button as MuiButton,
  useMediaQuery,
  Avatar,
} from "@mui/material";
import Button from "../../../components/Button";
import { PlanButton } from "../../Account/Plan";
import {
  arcticMistColor,
  primaryColor,
  silverSageColor,
} from "../../../assets/colors";
import { useEffect, useState } from "react";
import { MailchimpLogo, WoocommerceLogo } from "../../../assets/icons";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import {
  createWebgateRequestAction,
  getPluginsRequestAction,
} from "../../../redux/actions/webgateActions";
import { CREATE_WEBGATE_STARTED } from "../../../redux/actionTypes";
import Logo from "../../../assets/img/plugins/edd.jpg";

const Step2 = ({
  activeStep,
  setActiveStep,
  part,
  setPart,
  state,
  values,
  setSelectedPlugin,
  selectedPlugin,
}) => {
  const dispatch = useDispatch();

  const { rType, plugins } = useSelector((state) => state.webgate);
  const [system, setSystem] = useState("Provided");

  const websiteIp =
    values[0] + "." + values[1] + "." + values[2] + "." + values[3];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    dispatch(getPluginsRequestAction());
  }, []);

  const renderPart = () => {
    switch (part) {
      case 1:
        return (
          <>
            <Box sx={{ flex: 1 }}>
              <PlanButton
                sx={{ width: "100%", mb: 2 }}
                title="سیستم مدیریت محتوا آماده"
                desc="پیاده سازی شده توسط سیستم های مدیریت محتوا مانند وردپرس، جوملا و ..."
                onClick={() => setSystem("Provided")}
                isSelected={system === "Provided"}
              />
              <PlanButton
                sx={{ width: "100%" }}
                title="سیستم های مدیریت محتوا اختصاصی"
                desc="پیاده سازی شده توسط زبان های برنامه نویسی مانند PHP، Python و ..."
                onClick={() => setSystem("Exclusive")}
                isSelected={system === "Exclusive"}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                text={isMobile ? "قبلی" : "مرحله قبل"}
                onClick={() => setActiveStep(1)}
                fullWidth={false}
                color="arcticMist"
                sx={{
                  width: { xs: 93, lg: 156 },
                  border: `1px solid ${silverSageColor}`,
                }}
              />
              <Button
                text="مرحله بعد"
                onClick={
                  system === "Provided"
                    ? () => setPart(2)
                    : () =>
                        dispatch(
                          createWebgateRequestAction({
                            websiteAddress: state.websiteAddress,
                            websiteName: state.websiteName,
                            websiteIp,
                            setActiveStep,
                          })
                        )
                }
                fullWidth={false}
                sx={{ width: { xs: 196, lg: 156 } }}
                loading={rType === CREATE_WEBGATE_STARTED}
              />
            </Box>
          </>
        );

      case 2:
        return (
          <>
            <Box sx={{ flex: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: 1,
                }}
              >
                {plugins.map((plugin, index) => (
                  <CustomButton
                    key={index}
                    icon={require(`../../../assets/img/plugins/${plugin.logo}`)}
                    isSelected={selectedPlugin == plugin.cms_name_id}
                    onClick={() => setSelectedPlugin(plugin.cms_name_id)}
                    sx={{ mb: { xs: 2, lg: 0 } }}
                  />
                ))}
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                text={isMobile ? "قبلی" : "مرحله قبل"}
                onClick={() => {
                  setPart(1);
                  setSelectedPlugin(null);
                }}
                fullWidth={false}
                color="arcticMist"
                sx={{
                  width: { xs: 93, lg: 156 },
                  border: `1px solid ${silverSageColor}`,
                }}
              />
              <Button
                text="مرحله بعد"
                onClick={() =>
                  dispatch(
                    createWebgateRequestAction({
                      websiteAddress: state.websiteAddress,
                      websiteName: state.websiteName,
                      websiteIp,
                      cmsNameId: selectedPlugin,
                      setActiveStep,
                    })
                  )
                }
                fullWidth={false}
                sx={{ width: { xs: 196, lg: 156 } }}
              />
            </Box>
          </>
        );
    }
  };
  return (
    <>
      <Box
        sx={{
          width: { lg: 455 },
          pr: { lg: 3 },
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            position: "relative",
            alignItems: "center",
            mb: 4,
          }}
        >
          <Box
            sx={{
              width: 30,
              height: { xs: 18, lg: 32 },
              backgroundColor: primaryColor,
              position: "absolute",
              left: { xs: -35, lg: -25 },
              borderTopRightRadius: 32,
              borderBottomRightRadius: 32,
            }}
          ></Box>
          <Typography
            sx={{
              ml: { xs: 0.5, lg: 2 },
              fontSize: { xs: 14, lg: 20 },
              fontWeight: 600,
            }}
          >
            از کدام سیستم استفاده میکنید؟
          </Typography>
        </Box>
        {renderPart()}
      </Box>
      <Divider
        orientation="vertical"
        flexItem
        variant="middle"
        sx={{ mb: 10, display: { xs: "none", lg: "block" } }}
      />
    </>
  );
};

export default Step2;

export const CustomButton = ({ isSelected, icon, onClick, sx }) => {
  return (
    <Box
      onClick={onClick}
      component={MuiButton}
      sx={{
        width: { lg: 192, width: "100%" },
        height: 81,
        backgroundColor: arcticMistColor,
        p: 2,
        borderRadius: 2.5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: `1px solid ${silverSageColor}`,
        borderColor: isSelected ? primaryColor : silverSageColor,
        ...sx,
      }}
    >
      <Avatar
        src={icon}
        sx={{
          width: "auto",
          height: 60,
          mixBlendMode: "multiply",
        }}
        variant="square"
      />
    </Box>
  );
};
