import { Box, Grid, Typography } from "@mui/material";
import { brightRedColor, mistyRoseColor } from "../../assets/colors";
import { TrashIcon, WarningIcon } from "../../assets/icons";
import CustomTextInput from "../../components/CustomTextInput";
import Button from "../../components/Button";
import Title from "../../components/Title";

const DeleteAccount = () => {
  return (
    <Box
      sx={{
        flex: 1,
        py: { lg: 2.25 },
        px: { lg: 3.75 },
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid container sx={{ mb: "auto" }}>
        <Grid
          xs={12}
          item
          sx={{ mb: 6.5, display: { xs: "none", lg: "block" } }}
        >
          <Title text="حذف حساب کاربری" size={4} color={brightRedColor} />
        </Grid>
        <Grid
          xs={12}
          item
          sx={{
            p: "15px",
            mb: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: { lg: 490 } }}>
            <Box
              sx={{
                backgroundColor: mistyRoseColor,
                borderRadius: 3,
                p: 3,
                textAlign: "center",
                mb: 3,
              }}
            >
              <WarningIcon style={{ marginBottom: 5 }} />
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  lineHeight: 2,
                  textAlign: "left",
                }}
              >
                در صورتی که اکانت کاربری خود را حذف کنید، بر اساس قوانین و
                مقررات یک پی، اطلاعات مربوط به آن تا 5 سال نزد یک پی باقی خواهد
                ماند. همچنین پس از حذف اکانت خود، دیگر قادر به بازگرداندن آن
                نخواهید بود.
              </Typography>
            </Box>
            <Typography sx={{ fontSize: 14, mb: 2 }}>
              جهت حذف حساب کاربری ، رمز عبور خود را وارد کنید.
            </Typography>
            <Grid container spacing={2} sx={{ alignItems: "center" }}>
              <Grid item xs={12} lg={7}>
                <CustomTextInput
                  type="password"
                  label="رمز عبور"
                  placeholder="وارد کنید ..."
                />
              </Grid>
              <Grid item xs={5} sx={{ display: { xs: "none", lg: "block" } }}>
                <Button
                  text="حذف حساب"
                  startIcon={<TrashIcon />}
                  color="fireEngineRed"
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          left: 0,
          right: 0,
          p: 3,
          justifyContent: "center",
          display: { xs: "block", lg: "none" },
        }}
      >
        <Button
          text="حذف حساب"
          startIcon={<TrashIcon />}
          color="fireEngineRed"
        />
      </Box>
    </Box>
  );
};

export default DeleteAccount;
