import { Box, Grid, Typography } from "@mui/material";
import {
  MailIcon,
  SolarRefreshIcon,
  TimeZoneIcon,
  VerifiedUserOutlineIcon,
} from "../../assets/icons";
import {
  arcticMistColor,
  forestShadowColor,
  primaryColor,
  slateGrayColor,
} from "../../assets/colors";
import CustomAutocomplete from "../../components/CustomAutocomplete";
import MaterialUISwitch from "../../components/Switch";
import { useEffect, useState } from "react";
import Title from "../../components/Title";
import { useDispatch, useSelector } from "react-redux";
import {
  getGeneralSettingRequestAction,
  updateGeneralSettingRequestAction,
} from "../../redux/actions/settingActions";

const Setting = () => {
  const dispatch = useDispatch();

  const setting = useSelector((state) => state.setting);

  const [{ emailNotification, automaticRenewal, otp }, setInitState] = useState(
    {
      otp: setting.otp,
      automaticRenewal: setting.automaticRenewal,
      emailNotification: setting.emailNotification,
    }
  );

  const setState = (state) => {
    setInitState((prevState) => ({ ...prevState, ...state }));
  };

  useEffect(() => {
    dispatch(getGeneralSettingRequestAction({ setState }));
  }, []);

  return (
    <Box sx={{ p: "15px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ display: { xs: "none", lg: "block" } }}>
          <Title text="تنظیمات عمومی" size={4} />
        </Grid>
        {/* <Grid item xs={12}>
          <Box
            sx={{
              backgroundColor: arcticMistColor,
              borderRadius: 2,
              display: "flex",
              p: 2,
              alignItems: "center",
            }}
          >
            <Box sx={{ pr: 1.5, display: "flex", alignItems: "center" }}>
              <TimeZoneIcon />
            </Box>
            <Box sx={{ mr: "auto" }}>
              <Typography sx={{ fontSize: 14, fontWeight: 500, mb: 0.8 }}>
                موقعیت زمانی
              </Typography>
              <Typography sx={{ fontSize: 12, fontWeight: 300 }}>
                موقعیت مکانی خود را تعیین کنید
              </Typography>
            </Box>
            <Box>
              <CustomAutocomplete
                name="timezone"
                // label={t("timeZone")}
                value={timeZone}
                options={[{ timeZone: "" }]}
                startAdornment={
                  <Box sx={{ "& > img": { flexShrink: 0 } }}>
                    <img
                      loading="lazy"
                      // width="20"
                      // height="20"
                      // src={`https://flagcdn.com/w20/${timeZone?.code?.toLowerCase()}.png`}
                      // srcSet={`https://flagcdn.com/w40/${timeZone?.code?.toLowerCase()}.png 2x`}
                      src={`https://flagcdn.com/w20/ir.png`}
                      srcSet={`https://flagcdn.com/w40/ir.png 2x`}
                      alt=""
                      style={{ verticalAlign: "baseline" }}
                    />
                  </Box>
                }
                getOptionLabel={(option) => option.timezone}
                // onChange={(event, value) => setTimeZone(value)}
                // loading={setting.rType === GET_TIME_ZONES_STARTED}
              />
            </Box>
          </Box>
        </Grid> */}
        <Grid item xs={12}>
          <Item
            icon={<VerifiedUserOutlineIcon />}
            value={otp}
            onChange={() => {
              setState({ otp: !otp });
              dispatch(updateGeneralSettingRequestAction({ otp: !otp }));
            }}
            label="ورود دو مرحله ای به حساب"
            description="با فعال کردن این گزینه ، قبل از ورود یک رمز یکبار مصرف به ایمیل شما ارسال میشود."
          />
        </Grid>
        <Grid item xs={12}>
          <Item
            icon={<SolarRefreshIcon />}
            value={automaticRenewal}
            onChange={() => {
              setState({ automaticRenewal: !automaticRenewal });
              dispatch(
                updateGeneralSettingRequestAction({
                  automaticRenewal: !automaticRenewal,
                })
              );
            }}
            label="تمدید خودکار سطح حساب"
            description="با فعال کردن این گزینه ، قبل از ورود یک رمز یکبار مصرف به ایمیل شما ارسال میشود."
          />
        </Grid>
        <Grid item xs={12}>
          <Item
            icon={<MailIcon />}
            value={emailNotification}
            onChange={() => {
              setState({ emailNotification: !emailNotification });
              dispatch(
                updateGeneralSettingRequestAction({
                  emailNotification: !emailNotification,
                })
              );
            }}
            label="اطلاع رسانی از ایمیل"
            description="با فعال کردن این گزینه ، قبل از ورود یک رمز یکبار مصرف به ایمیل شما ارسال میشود."
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Setting;

const Item = ({ icon, label, description, value, onChange }) => {
  return (
    <Box sx={{ backgroundColor: arcticMistColor, borderRadius: 2, p: 2 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ pr: 1.5, display: "flex", alignItems: "center" }}>
          {icon}
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 500,
              mb: { xs: 0, lg: "6px" },
            }}
          >
            {label}
          </Typography>
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 300,
              display: { xs: "none", lg: "block" },
            }}
          >
            {description}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", ml: "auto" }}>
          <Typography
            sx={{
              fontSize: 13,
              color: value ? slateGrayColor : forestShadowColor,
              display: { xs: "none", lg: "block" },
            }}
          >
            غیر فعال
          </Typography>
          <MaterialUISwitch
            checked={value}
            onChange={onChange}
            name="checkedA"
            color="primary"
          />
          <Typography
            sx={{
              fontSize: 13,
              color: value ? primaryColor : forestShadowColor,
              display: { xs: "none", lg: "block" },
            }}
          >
            فعال
          </Typography>
        </Box>
      </Box>
      <Typography
        sx={{ fontSize: 12, fontWeight: 300, display: { lg: "none" }, mt: 2 }}
      >
        {description}
      </Typography>
    </Box>
  );
};
