import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import {
  arcticMistColor,
  blackColor,
  brightRedColor,
  crimsonBlazeColor,
  errorColor,
  graphiteGrayColor,
  mistyGreenColor,
  primaryColor,
  silverSageColor,
  whiteColor,
} from "../../../assets/colors";
import {
  CloseIcon,
  EditIcon,
  EuroIcon,
  EuroIcon2,
  ImageIcon,
  InfoIcon,
} from "../../../assets/icons";
import { useRef, useState } from "react";
import { appBarHeight, drawerWidth } from "../../../utils/constants";
import Button from "../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import CustomTextInput from "../../../components/CustomTextInput";
import { CREATE_PRODUCT_STARTED } from "../../../redux/actionTypes";
import CurrencyInput from "../../../components/CurrencyInput";

const AddProduct = ({ open, setOpen, setCount, onAdd }) => {
  const fileInputRef = useRef(null);

  const { rType } = useSelector((state) => state.product);
  const [{ image, title, description, amount, detail, errors }, setInitState] =
    useState({
      image: null,
      title: "",
      detail: "",
      description: "",
      amount: "",
      errors: {},
    });

  const setState = (state) => {
    setInitState((prevState) => ({ ...prevState, ...state }));
  };

  const handleAddProduct = (e) => {
    e.preventDefault();
    const errorObj = {};

    if (image === null) errorObj.image = "لطفا عکس محصول را انتخاب کنید.";
    if (title === "") errorObj.title = "لطفا اسم محصول را وارد کنید.";
    if (Number(amount) === 0)
      errorObj.amount = "قیمت محصول نمی‌تواند صفر باشد.";
    if (amount === "") errorObj.amount = "لطفا قیمت محصول را وارد کنید.";

    setState({ errors: errorObj });

    if (Object.keys(errorObj).length > 0) return;

    let formData = new FormData();
    formData.append("title", title);
    formData.append("detail", detail);
    formData.append("description", description);
    formData.append("amount", amount);
    formData.append("image", image);

    onAdd && onAdd({ formData, setOpen, setState, setCount });
  };

  const handleSelectImage = (e) => {
    let error = false;
    const errorObj = { ...error };

    let file = e.target.files[0];
    const fileExtension = file?.name.split(".").pop().toLowerCase();

    console.log(fileExtension);

    if (file?.size > 2000000) {
      errorObj.size = true;
    }
    if (!".png, .jpg, .jpeg".includes(fileExtension)) {
      errorObj.type = true;
    }

    setState({ errors: errorObj });

    if (Object.keys(errorObj).length > 0) return;

    setState({ image: file });
  };

  const renderContent = (
    <>
      <IconButton
        aria-label="close"
        onClick={() => setOpen(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon color={whiteColor} width={24} height={24} />
      </IconButton>
      <DialogTitle
        sx={{ backgroundColor: blackColor, fontSize: 16, color: whiteColor }}
      >
        افزودن محصول جدید
      </DialogTitle>
      <DialogContent dividers sx={{ borderBottom: "none" }}>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleSelectImage}
          // accept=".png, .jpg, .jpeg"
        />
        {image ? (
          <Box
            sx={{
              height: 111,
              width: 111,
              border: `1px dashed ${silverSageColor}`,
              p: 1,
              mx: "auto",
              borderRadius: 1.25,
              position: "relative",
              mb: 2,
            }}
          >
            <Box
              component="img"
              src={URL.createObjectURL(image)}
              sx={{ width: 96, height: "100%" }}
            />
            <Chip
              label="ویرایش"
              sx={{
                color: primaryColor,
                position: "absolute",
                bottom: -10,
                left: 22,
                fontSize: 10,
                backgroundColor: arcticMistColor,
                height: 18,
              }}
              onClick={(e) => {
                setState({ image: null });
                fileInputRef.current.click();
              }}
              icon={<EditIcon width={12} height={12} color={primaryColor} />}
            />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                backgroundColor: arcticMistColor,
                padding: 1.25,
                borderRadius: 3,
                mb: 0.5,
                display: "flex",
                border: `1px solid ${
                  errors.image ? errorColor : arcticMistColor
                }`,
              }}
            >
              <Box sx={{ flex: 1, mr: 1 }}>
                <Box sx={{ display: "flex" }}>
                  <Box>
                    <InfoIcon
                      style={{ marginTop: 2 }}
                      color={errors.size ? crimsonBlazeColor : mistyGreenColor}
                      width={20}
                      height={20}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: 12,
                      color: errors.size
                        ? crimsonBlazeColor
                        : graphiteGrayColor,
                      lineHeight: 2,
                      ml: 1,
                    }}
                  >
                    لطفاً عکس را با حجم کمتر از ۲ مگابایت انتخاب کنید.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Box>
                    <InfoIcon
                      style={{ marginTop: 2 }}
                      color={errors.type ? crimsonBlazeColor : mistyGreenColor}
                      width={20}
                      height={20}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: 12,
                      color: errors.type
                        ? crimsonBlazeColor
                        : graphiteGrayColor,
                      lineHeight: 2,
                      ml: 1,
                    }}
                  >
                    لطفاً فقط عکس‌هایی با فرمت PNG، JPG یا JPEG بارگذاری کنید.
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  border: `1px solid ${silverSageColor}`,
                  width: 111,
                  height: 111,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 1.25,
                  cursor: "pointer",
                }}
                onClick={() => fileInputRef.current.click()}
              >
                <ImageIcon />
                <Typography sx={{ fontSize: 12 }}>عکس محصول</Typography>
                <Typography sx={{ color: primaryColor, fontSize: 12 }}>
                  +افزودن
                </Typography>
              </Box>
            </Box>
            <Typography
              sx={{ fontSize: 12, color: errorColor, mb: 2, height: 18 }}
            >
              {errors.image}
            </Typography>
          </>
        )}
        <CustomTextInput
          label="عنوان"
          error={errors.title}
          helperText={errors.title}
          onChange={(e) =>
            setState({
              title: e.target.value,
              errors: { ...errors, title: "" },
            })
          }
          value={title}
        />
        <CustomTextInput
          label="جزئیات (اختیاری)"
          onChange={(e) => setState({ detail: e.target.value })}
          value={detail}
        />
        <CustomTextInput
          label="توضیحات (اختیاری)"
          multiline
          rows={3}
          onChange={(e) => setState({ description: e.target.value })}
          value={description}
        />
        <CurrencyInput
          label="قیمت محصول"
          startAdornment={<EuroIcon />}
          error={errors.amount}
          helperText={errors.amount}
          onValueChange={(values, sourceInfo) => {
            console.log(values, sourceInfo);

            setState({
              amount: values.value,
              errors: { ...errors, amount: "" },
            });
          }}
          decimalScale={2}
          thousandSeparator
          endAdornment={<EuroIcon2 width={18} />}
        />
      </DialogContent>
      <DialogActions>
        <Button
          text="ایجاد محصول"
          onClick={handleAddProduct}
          loading={rType === CREATE_PRODUCT_STARTED}
        />
      </DialogActions>
    </>
  );

  return (
    <>
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        // componentsProps={{
        //   backdrop: {
        //     sx: { left: { lg: drawerWidth }, backgroundColor: "transparent" },
        //   },
        // }}
        PaperProps={{
          sx: {
            borderTopRightRadius: { xs: 20, lg: 25 },
            borderTopLeftRadius: { xs: 20, lg: 25 },
            // p: 2,
            boxShadow: "0px -17px 44.3px 0px #7F91A133",
          },
        }}
        sx={{ display: { lg: "none" } }}
      >
        {renderContent}
      </SwipeableDrawer>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            borderRadius: 5,
            boxShadow: "0px 0px 77px 20px #5F5F5F08;",
            width: 488,
          },
        }}
        BackdropProps={{
          sx: {
            background: "rgba(218, 218, 218, 0.21)",
            backdropFilter: " blur(9px)",
            left: drawerWidth,
            top: appBarHeight,
            borderLeft: "1px solid #E7EFEC",
          },
        }}
        sx={{
          left: drawerWidth,
          top: appBarHeight,
          display: { xs: "none", lg: "block" },
        }}
        onClose={() => setOpen(false)}
        scroll="body"
      >
        {renderContent}
      </Dialog>
    </>
  );
};

export default AddProduct;
